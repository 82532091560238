<template>
  <div class="bg-white">
    <v-navigation-drawer absolute temporary v-model="openMenu">
      <side-menu />
    </v-navigation-drawer>
    <loading-spinner />
    <error-message-dialog />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_exitingRequestAdd" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <!-- 1行目 -->
          <v-row class="mt-1 d-flex gap-3">
            <!-- 取引先 -->
            <c-client-input
              ref="clientInput"
              v-model="searchForm.clientSid"
              required
              auto-select-first
              width="250px"
            />
            <!-- 品番/品名 -->
            <c-item-input
              v-model="searchForm.itemStandardSid"
              :clientSid="searchForm.clientSid"
              width="250px"
            />
            <!-- 所属 -->
            <c-belong-input v-model="searchForm.belongSid" width="250px" />
            <!-- 部品要求者 -->
            <c-user-input
              v-model="searchForm.userSid"
              :label="$t('label.lbl_partsRequester')"
              width="250px"
            />
            <!-- グループ -->
            <div class="search-autocomplete" style="width: 250px">
              <v-text-field
                v-model="searchForm.groupName"
                :label="$t('label.lbl_groupName')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                outlined
                dense
                :rules="[rules.isStrLen(searchForm.groupName, 200)]"
              />
            </div>
            <!-- 管理番号 -->
            <div class="search-autocomplete" style="width: 250px">
              <v-text-field
                v-model="searchForm.itemManageNo"
                :label="$t('label.lbl_managementNoJP')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                outlined
                dense
                :rules="[rules.isStrLen(searchForm.itemManageNo, 20)]"
              />
            </div>
            <!-- 品質区分 -->
            <c-code-input
              v-model="searchForm.qualityDiv"
              :codeType="appConfig.CODETYPE.IN_QUALITY_DIV"
              width="250px"
            />
          </v-row>
          <!-- 2行目 -->
          <v-row class="d-flex justify-space-between gap-3 align-center">
            <!--ボタン領域-->
            <div>
              <!-- 検索ボタン -->
              <v-btn class="get-btn ml-0" color="primary" id="get-search" @click="searchBtn()">{{
                $t("btn.btn_search")
              }}</v-btn>
              <!-- 画面クリアボタン -->
              <v-btn color="primary" id="btn-search" class="other-btn" @click="clearBtnDialog()">{{
                $t("btn.btn_clear")
              }}</v-btn>
            </div>
            <div>
              <!-- 仮登録リスト追加ボタン -->
              <v-btn
                color="primary"
                id="btn-search"
                class="other-btn-nosize"
                @click="tmpAddBtn()"
                :disabled="!(selectionState.isIndeterminate || selectionState.isAllSelected)"
                >{{ $t("btn.btn_addTempRegList") }}</v-btn
              >
              <!-- 仮登録リスト全削除ボタン -->
              <v-btn
                color="primary"
                id="btn-search"
                class="other-btn-nosize"
                @click="tmpAllDeleteBtnDialog()"
                :disabled="!isTmpAddListFlg"
                >{{ $t("btn.btn_clearTempRegList") }}</v-btn
              >
              <!-- 仮登録リスト確認ボタン -->
              <v-btn
                :color="isTmpAddListFlg ? 'error' : 'primary'"
                id="btn-search"
                class="other-btn-nosize"
                @click="tmpConfirmBtn()"
                >{{ $t("btn.btn_viewTempRegList") }}</v-btn
              >
            </div>
          </v-row>
          <!-- 4行目 -->
          <v-row class="d-flex justify-space-between gap-3 align-center pt-2">
            <div>
              <!--検索結果件数表示-->
              <span>{{
                $t("label.lbl_total") + "：" + pagination.totalCount + $t("label.lbl_number")
              }}</span>
            </div>
            <!--表示件数切替ボタン-->
            <v-btn-toggle
              v-model="pagination.itemsPerPage"
              color="blue darken-2"
              class="toggle black--text"
              right
              mandatory
            >
              <v-btn class="pasing-btn" :value="100" small>
                {{ 100 }}
              </v-btn>
              <v-btn class="pasing-btn" :value="250" small>
                {{ 250 }}
              </v-btn>
              <v-btn class="pasing-btn" :value="500" small>
                {{ 500 }}
              </v-btn>
            </v-btn-toggle>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="pagination.currentPage"
          :items-per-page="pagination.itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          height="575px"
        >
          <!-- ヘッダーチェックボックス -->
          <template v-slot:[`header.check`]="{}">
            <v-checkbox
              class="d-inline-flex"
              v-model="selectionState.isAllSelected"
              :indeterminate="selectionState.isIndeterminate"
              hide-details
              dense
            ></v-checkbox>
          </template>
          <!-- 一覧チェックボックス -->
          <template v-slot:[`item.check`]="{ item }">
            <v-checkbox class="d-inline-flex" v-model="item.check" hide-details dense></v-checkbox>
          </template>
          <!-- 所属 -->
          <template v-slot:[`item.officialName`]="{ item }">
            <c-table-column-tooltip v-model="item.officialName" />
          </template>
          <!-- 部品要求者 -->
          <template v-slot:[`item.userName`]="{ item }">
            <c-table-column-tooltip v-model="item.userName" />
          </template>
          <!-- グループ -->
          <template v-slot:[`item.groupName`]="{ item }">
            <c-table-column-tooltip v-model="item.groupName" />
          </template>
          <!-- 品番/品名 -->
          <template v-slot:[`item.item`]="{ item }">
            <c-table-column-tooltip v-model="item.item" />
          </template>
          <!-- 倉庫 -->
          <template v-slot:[`item.warehouseName`]="{ item }">
            <c-table-column-tooltip v-model="item.warehouseName" />
          </template>
          <!-- ロケーション -->
          <template v-slot:[`item.locationNo`]="{ item }">
            <c-table-column-tooltip v-model="item.locationNo" />
          </template>
          <!-- 管理番号 -->
          <template v-slot:[`item.itemManageNo`]="{ item }">
            <c-table-column-tooltip v-model="item.itemManageNo" :maxWidth="205" />
          </template>
          <!--入り数 -->
          <template v-slot:[`item.stock`]="{ item }">
            <c-table-column-tooltip v-model="item.stock" />
          </template>
          <!-- 保管期限 -->
          <template v-slot:[`item.retentionDuedate`]="{ item }">
            <c-table-column-tooltip v-model="item.retentionDuedate" />
          </template>
          <!-- 品質区分 -->
          <template v-slot:[`item.qualityName`]="{ item }">
            <c-table-column-tooltip v-model="item.qualityName" />
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="pagination.currentPage"
            :length="pagination.totalPage"
            :total-visible="7"
          ></v-pagination>
        </div>
      </v-container>
    </v-sheet>
    <ConfirmDialog
      :isShow.sync="ConfirmDialog.isOpen"
      :title="ConfirmDialog.title"
      :message="ConfirmDialog.message"
      :screenFlag="ConfirmDialog.screenFlag"
      :changeFlag="ConfirmDialog.changeFlag"
      :okAction="ConfirmDialog.okAction"
    />
  </div>
</template>

<script>
import { commonUtil } from "@/assets/scripts/js/CommonUtil";
import { messsageUtil } from "@/assets/scripts/js/MesssageUtil";
import { i18n } from "@/lang/lang.js";
import { appConfig } from "@/assets/scripts/js/AppConfig";
import { httpClient } from "@/assets/scripts/js/HttpClient";
import ConfirmDialog from "@/components/ConfirmDialog";
import commonMixin from "@/mixins/CommonMixin";
import paginationMixin from "@/mixins/PaginationMixin.js";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.SCREEN_ID.P_EXT_101,
  mixins: [commonMixin, commonRules, paginationMixin],
  components: {
    ConfirmDialog,
  },
  data: () => ({
    appConfig,
    // 検索フォーム
    searchForm: {
      // 取引先
      clientSid: "",
      // 品番/品名
      itemStandardSid: "",
      //  所属
      belongSid: "",
      // 部品要求者
      userSid: "",
      // グループ
      groupName: "",
      // 管理番号
      itemManageNo: "",
      // 品質区分
      qualityDiv: "",
    },
    // メニュー
    openMenu: null,
    dateMenu: false,
    // 仮登録リスト確認の有無フラグ
    isTmpAddListFlg: false,
    // テーブルフッター項目
    inputList: [],
    // テーブルヘッダ項目
    headerItems: [
      // 選択
      {
        text: i18n.tc("btn.btn_select"),
        value: "check",
        width: "5%",
        align: "center",
        sortable: false,
      },
      // 所属
      {
        text: i18n.tc("label.lbl_belong"),
        value: "officialName",
        width: "10%",
        align: "left",
        sortable: false,
      },
      // 部品要求者
      {
        text: i18n.tc("label.lbl_partsRequester"),
        value: "userName",
        width: "10%",
        align: "left",
        sortable: false,
      },
      // グループ
      {
        text: i18n.tc("label.lbl_groupName"),
        value: "groupName",
        width: "10%",
        align: "left",
        sortable: false,
      },
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "item",
        width: "10%",
        align: "left",
        sortable: false,
      },
      // 倉庫
      {
        text: i18n.tc("label.lbl_warehouse"),
        value: "warehouseName",
        align: "left",
        width: "10%",
        sortable: false,
      },
      // ロケーション
      {
        text: i18n.tc("label.lbl_location"),
        value: "locationNo",
        align: "left",
        width: "10%",
        sortable: false,
      },
      // 管理番号
      {
        text: i18n.tc("label.lbl_managementNoJP"),
        value: "itemManageNo",
        align: "left",
        width: "10%",
        sortable: false,
      },
      // 入り数
      {
        text: i18n.tc("label.lbl_productHacQty"),
        value: "stock",
        align: "center",
        width: "8%",
        sortable: false,
      },
      // 保管期限
      {
        text: i18n.tc("label.lbl_retentionDuedate"),
        value: "retentionDuedate",
        align: "left",
        width: "10%",
        sortable: false,
      },
      // 品質区分
      {
        text: i18n.tc("label.lbl_arrivalStatus"),
        value: "qualityName",
        align: "left",
        width: "10%",
        sortable: false,
      },
    ],
  }),
  methods: {
    // 初期化
    init() {
      // 選択状態管理：対象項目
      this.selectionState.targetColumn = "itemManageNo";
      // ページング：リフレッシュ処理
      this.pagination.afterFunction = this.searchBtn.bind(null, true);
    },

    // 検索ボタン
    searchBtn(refreshFlg = false) {
      if (refreshFlg || this.$refs.form.validate()) {
        // ローディング画面表示ON
        this.loadingSpinner.counter++;
        // リフレッシュ
        if (refreshFlg && Object.keys(this.searchFilter).length != 0) {
          Object.assign(this.searchForm, this.searchFilter);
        }
        // 検索
        const config = httpClient.createGetApiRequestConfig();
        // 検索条件
        Object.assign(config.params, this.searchForm);
        // ページング
        Object.assign(config.params, {
          reqComPaginationFlg: "1",
          reqComPageIndex: refreshFlg ? this.pagination.currentPage : 1,
          reqComPageLimit: this.pagination.itemsPerPage,
        });
        return new Promise((resolve, reject) => {
          httpClient
            .secureGet(appConfig.API_URL.BIZ_WARE_OUT_REQUEST_PARTS, config)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                const list = [];
                jsonData.resIdv.results.forEach((row) => {
                  list.push({
                    ...row,
                    // チェック
                    check: false,
                    // 品番/品名
                    item: [row.itemCd, row.itemName],
                    // 入り数
                    stock: commonUtil.formatToCurrency(Number(row.stock ?? 0)),
                  });
                });
                this.inputList = list;
                // チェック処理
                this.searchAfterSelect();
                // 総件数
                this.pagination.totalCount = jsonData.resCom.resComPagination.totalRecord;
                // 総ページ数
                this.pagination.totalPage = jsonData.resCom.resComPagination.totalPage;
                // 検索値保持
                Object.assign(this.searchFilter, this.searchForm);
                if (!refreshFlg) {
                  // 初期化
                  this.resetSelectionState();
                }
                resolve();
              } else {
                if (!this.errorMessage.isError) {
                  this.errorMessage.isError = true;
                  this.errorMessage.message = jsonData.resCom.resComMessage;
                }
                reject(jsonData.resCom.resComMessage);
              }
            })
            .catch((ex) => {
              if (!this.errorMessage.isError) {
                this.errorMessage.isError = true;
                this.errorMessage.message = ex;
              }
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingSpinner.counter--;
            });
        });
      }
    },

    // クリアボタンダイアログ
    clearBtnDialog() {
      this.ConfirmDialog.title = i18n.tc("btn.btn_ok");
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = this.clearBtn;
    },
    // クリアボタン
    clearBtn() {
      this.inputList = [];
      this.$refs.form.reset();
      this.searchFilter = {};
      this.resetPagination();
    },

    // 仮登録リスト追加ボタン
    tmpAddBtn() {
      // ローディング画面表示ON
      this.loadingSpinner.counter++;
      // 登録処理
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_EXT_101;
      // 選択状態
      this.setSelectionState(body.reqCom);
      // 検索条件
      body.reqCom.reqComSearchCriteria = this.searchFilter;
      return new Promise((resolve, reject) => {
        httpClient
          .securePost(appConfig.API_URL.BIZ_WARE_OUT_REQUEST_TEMP_CREATE_GET_ADD_PARTS, body)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // 初期化
              this.resetSelectionState();
              // 検索
              this.searchBtn(true);
              // 仮登録リスト確認の有無フラグ
              this.isTmpAddListFlg = true;
              resolve();
            } else {
              if (!this.errorMessage.isError) {
                this.errorMessage.isError = true;
                this.errorMessage.message = jsonData.resCom.resComMessage;
              }
              reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((ex) => {
            if (!this.errorMessage.isError) {
              this.errorMessage.isError = true;
              this.errorMessage.message = ex;
            }
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingSpinner.counter--;
          });
      });
    },

    // 仮登録リスト全削除ボタンダイアログ
    tmpAllDeleteBtnDialog() {
      this.ConfirmDialog.title = i18n.tc("btn.btn_ok");
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clearTempRegList"),
      ]);
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = this.tmpAllDeleteBtn;
    },
    // 仮登録リスト全削除ボタン
    tmpAllDeleteBtn() {
      // ローディング画面表示ON
      this.loadingSpinner.counter++;
      // 全削除処理
      const config = httpClient.createGetApiRequestConfig();
      return new Promise((resolve, reject) => {
        httpClient
          .secureDelete(
            appConfig.API_URL.BIZ_WARE_OUT_REQUEST_TEMP_CREATE_GET_ALLDELETE_PARTS,
            config
          )
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // 初期化
              this.resetSelectionState();
              this.clearBtn();
              // 仮登録リスト確認の有無フラグ
              this.isTmpAddListFlg = false;
              resolve();
            } else {
              if (!this.errorMessage.isError) {
                this.errorMessage.isError = true;
                this.errorMessage.message = jsonData.resCom.resComMessage;
              }
              reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((ex) => {
            if (!this.errorMessage.isError) {
              this.errorMessage.isError = true;
              this.errorMessage.message = ex;
            }
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingSpinner.counter--;
          });
      });
    },

    // 仮登録リスト確認ボタン
    tmpConfirmBtn() {
      this.$router.push({
        name: appConfig.SCREEN_ID.P_EXT_102,
        params: {
          // 取引先SID
          clientSid: this.searchForm.clientSid,
          // 出荷先用の取引先SID
          shipToClientSid: this.$refs.clientInput.internalItems.find(
            (item) => item.value == this.searchForm.clientSid
          ).clientSid,
        },
      });
    },

    // 仮登録リスト確認ボタン色制御
    tmpAddListCheckBtnColorControl() {
      // ローディング画面表示ON
      this.loadingSpinner.counter++;
      // 仮登録リスト取得処理
      const config = httpClient.createGetApiRequestConfig();
      Object.assign(config.params, this.searchForm);
      return new Promise((resolve, reject) => {
        httpClient
          .secureGet(appConfig.API_URL.BIZ_WARE_OUT_REQUEST_TEMP_CREATE_GET_PARTS, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              if (jsonData.resIdv.results.length > 0) {
                // 仮登録リスト確認の有無フラグ
                this.isTmpAddListFlg = true;
              }
              resolve();
            } else {
              if (!this.errorMessage.isError) {
                this.errorMessage.isError = true;
                this.errorMessage.message = jsonData.resCom.resComMessage;
              }
              reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((ex) => {
            if (!this.errorMessage.isError) {
              this.errorMessage.isError = true;
              this.errorMessage.message = ex;
            }
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingSpinner.counter--;
          });
      });
    },
  },
  computed: {},
  watch: {
    isMenu: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    // 取引先SID
    "searchForm.clientSid": function (newValue) {
      if (newValue) {
        this.tmpAddListCheckBtnColorControl();
      }
    },
  },
  mounted() {
    this.init();
  },
  created() {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
.gap-3 > * {
  margin-left: 12px;
}
.gap-3 > *:last-child {
  margin-right: 12px;
}
.v-input--checkbox::v-deep {
  .v-label,
  .v-icon {
    font-size: 34px;
  }
}
#listData ::v-deep th,
#listData ::v-deep td {
  padding: 0 0 0 16px;
}
</style>
